<template>
  <div class="filters" ref="filtersWrapper">
    <a-popover
      trigger="click"
      placement="bottom"
      v-model:open="visible"
      overlayClassName="filters-popover"
      :getPopupContainer="() => $refs.filtersWrapper"
    >
      <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filtersNum"
        showDownIcon="true"
        >Filters
      </artmisads-select-button>
      <template #content>
        <div class="filters-down" ref="filtersDown">
          <div class="filters-name">Commission Min</div>
          <artmisads-input-number
            min="0"
            max="100"
            suffix="%"
            :step="0.1"
            placeholder="Please enter..."
            v-model:value="filters.commission"
            @change="handleChange($event, 'comission')"
          />
          <div class="filters-name">Availability</div>
          <div>
            <artmisads-select
              v-model:value="filters.availability"
              style="width: 100%"
              @change="handleChange($event, 'availability')"
              :getPopupContainer="() => $refs.filtersDown"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK">In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK"
                >Out of Stock</a-select-option
              >
            </artmisads-select>
          </div>
          <div class="filters-name">Price</div>
          <div>
            <artmisads-select
              v-model:value="filters.price"
              style="width: 100%"
              @change="(v, option) => handleChange(option, 'price')"
              :getPopupContainer="() => $refs.filtersDown"
              :options="priceOptions"
              placement="topRight"
            />
          </div>
        </div>
        <div class="btns">
          <a
            @click="reset"
            :class="'reset' + (filtersNum ? '' : ' disabled')"
            href="javascript:;"
          >
            <span style="margin-right: 3px">Reset</span
            ><span class="filter-num" v-show="filtersNum">{{ filtersNum }}</span
            ><span style="margin-left: 3px">Filters</span>
          </a>
          <artmisads-button class="save" type="primary" @click="updateFilters"
            >Confirm</artmisads-button
          >
        </div>
      </template>
    </a-popover>
  </div>
</template>
<script setup>
import { PRICE } from '../../../contants/publisher'
import { ref, defineEmits, defineProps } from 'vue'
import { CURRENCY_MAPPER } from '../../../contants/common'

const { init } = defineProps(['init'])
const emit = defineEmits(['updateFilters'])

const filters = ref({ ...init })

let visible = ref(false)
let filtersWrapper = ref()
let filtersDown = ref()
let filtersNum = ref(0)
const priceOptions = ref([])
let filtersOrigi = {
  price: 'all',
  commission: null,
  availability: 'all',
}
let options = []

function setPriceCurrency() {
  priceOptions.value = PRICE.map((item) => {
    return {
      ...item,
      label: item.label.replace(
        /%s/g,
        CURRENCY_MAPPER[filters.value.countryCode].currency
      ),
      value: item.label,
      minPrice: item.min ? filters.value.currency + item.min : null,
      maxPrice: item.max ? filters.value.currency + item.max : null,
    }
  })
  filters.value.price = filters.value.price.replace(
    /[$|£|€]/g,
    filters.value.currency
  )
}

setPriceCurrency()

function updateFilters() {
  visible.value = false
  emit('updateFilters', filters.value)
}

const handleChange = (e, itemName) => {
  let isMark = options.includes(itemName)

  if (!isMark && e != filtersOrigi[itemName]) {
    options.push(itemName)
    filtersNum.value++
  }

  if (isMark && e == filtersOrigi[itemName]) {
    let index = options.findIndex((item) => item == itemName)

    options.splice(index, 1)

    if (filtersNum) {
      filtersNum.value--
    }
  }
  if (itemName == 'comission') {
    filters.value.commission = e

    if (isNaN(parseFloat(e))) {
      filters.value.commission = undefined
      return
    }

    filters.value.commission = Math.floor(e * 10) / 10
  } else if (itemName === 'price') {
    filters.value.minPrice = e.min || null
    filters.value.maxPrice = e.max || null
  } else if (itemName === 'availability') {
    filters.value.availability = e
  }
}
const reset = () => {
  options = []
  filtersNum.value = 0
  visible.value = false
  filters.value = {
    ...filters.value,
    price: 'All',
    minPrice: '',
    maxPrice: '',
    availability: 'all',
    commission: undefined,
  }

  emit('updateFilters', filters.value)
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/filter-popup.less';

.filters-down:deep(.ant-input-number-handler-wrap) {
  opacity: 1 !important;
}
</style>