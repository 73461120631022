<template>
  <div class="brand-detail">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-pinpai"></use>
      </svg>
    </breadcrumbs>
    <a-spin :spinning="load">
      <div class="brand-overview">
        <h2>Brand Overview</h2>
        <div style="padding-left: 16px; background-color: #fff">
          <a-divider />
        </div>
        <div class="brand-profile">
          <div class="brand-image">
            <img
              :src="brandDetail?.logoImageUrl"
              v-if="brandDetail?.logoImageUrl"
            />
            <span v-else></span>
          </div>
          <div class="brand-content">
            <div class="brand-details">
              <div class="bio">
                {{ brandTitle
                }}<span class="country" v-if="brandDetail.countryCode">
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        '#icon-guoqi-' + brandDetail.countryCode.toLowerCase()
                      "
                    ></use>
                  </svg>
                  {{ CURRENCY_MAPPER[brandDetail.countryCode].host }}</span
                >
              </div>
              <div class="content">
                {{ brandDetail.biography ? brandDetail.biography : '--' }}
              </div>
            </div>
            <a
              class="brand-terms"
              :href="brandDetail?.termsFileUrl"
              target="_blank"
              >Brand Terms and Conditions<svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-tiaozhuan"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="table-card">
        <div class="card-header">Products for this Brand</div>
        <div style="background-color: var(--color-white); padding-left: 16px">
          <a-divider />
        </div>
        <div class="table">
          <div class="table-head">
            <div class="flex-left">
              <top-pagination
                v-if="productLoaded"
                :pagination="{
                  size: filter.size,
                  current: filter.page,
                  total: pagination.total,
                }"
                @pageChange="(page) => setFilter('page', page)"
              />
              <a-input-search
                allow-clear
                class="search"
                placeholder="Search..."
                @search="(v) => setFilter('keywords', v)"
                v-model:value="filter.keywords"
              />
            </div>
            <div class="flex-right">
              <div class="deals-filter">
                <span>Deals:</span>
                <artemisads-segmented v-model:value="filter.deal" @change="(v) => setFilter('deal', v)"
                  :options="['All', 'Active', 'Upcoming', 'Off']" />
              </div>
              
              <FilterOptions
                @updateFilters="(data) => setFilter('popup', data)"
                :init="filter"
              />
              <artmisads-select
                class="with-sort-icon"
                v-model:value="filter.sort"
                style="width: 160px"
                @change="(option) => setFilter('sort', option)"
              >
                <a-select-option value="%2BbestSellers"
                  >Best Sellers</a-select-option
                >
                <a-select-option value="-newlyAdded"
                  >Newly Added</a-select-option
                >
                <a-select-option value="-commission"
                  >Commission</a-select-option
                >
                <a-select-option value="-price">Price</a-select-option>
              </artmisads-select>
            </div>
          </div>
          <artmisads-table
            :columns="columns"
            class="table-content"
            :scroll="{ x: 1360 }"
            :pagination="pagination"
            :data-source="dataSource"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'title'">
                <a
                  class="product-name"
                  :href="'/publisher/products/' + record.id"
                  target="_blank"
                >
                  <div
                    :class="
                      'product-img-show' + (record.imageUrl ? '' : ' show-bg')
                    "
                  >
                    <img :src="record.imageUrl" v-if="record.imageUrl" />
                  </div>
                  <div class="product-title">
                    <span>{{ record.title }}</span>
                    <div class="asin">{{ record.asin }}</div>
                  </div>
                </a>
              </template>
              <template v-if="column.key === 'category'">
                <a
                  class="category"
                  target="_blank"
                  href="/publisher/products"
                  @click="gotoProductCategory(record)"
                  >{{ record.category }}</a
                >
              </template>
              <template v-else-if="column.key === 'deals'">
                <productDealsColumn
                  :record="item"
                  v-if="record.productDeals?.length"
                  v-for="item in record.productDeals"
                  :key="item.id"
                />
                <div class="no-deal" v-else>--</div>
              </template>
              <template v-else-if="column.key === 'price'">
                <price :record="record" />
              </template>
            </template>
          </artmisads-table>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script lang="jsx" setup>
import { message } from 'ant-design-vue'
import { ref, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import brandApi from '@/server/seller/brands'
import { useUserInfoStore } from '@/storeData'
import TopPagination from '../../../components/TopPagination.vue'
import FilterOptions from './filters.vue'
import Price from '~/components/Price.vue'
import breadcrumbs from '../../seller/breadcrumbs.vue'
import productDealsColumn from '~/components/productDealsColumn.vue'
import { publisherApi } from '~/server'
import { CURRENCY_MAPPER } from '../../../contants/common'
const breadcrumbsData = reactive({
  title: 'Your Brands',
  des: '',
  color: '#FFD358',
  links: [
    {
      name: 'Home',
      path: '/publisher/products',
    },
    {
      name: 'Brands',
      path: '/publisher/brands',
    },
  ],
})
const brandTitle = ref()
const load = ref(true)
const route = useRoute()
const productLoaded = ref(false)
const userInfo = useUserInfoStore()
const columns = ref([
  {
    title: 'Product',
    key: 'title',
    dataIndex: 'title',
    fixed: 'left',
    width: 500
  },
  {
    width: 100,
    key: 'deals',
    title: 'Deals',
  },
  {
    width: 200,
    key: 'category',
    title: 'Category',
    dataIndex: 'category',
  },
  {
    width: 200,
    key: 'price',
    title: 'Price',
    dataIndex: 'price'
  },
  {
    title: 'Commission',
    key: 'commission',
    dataIndex: 'commission',
    width: 120,
    customRender: ({ text, record }) => (
      <div class="product-commission">{text}%</div>
    ),
  },
  {
    title: 'Est. Payout',
    key: 'estPayout',
    dataIndex: 'estPayout',
    width: 120,
    customRender: ({ text, record }) => (
      <div class="fw500 est-payout">{record.currencySymbol}{Number(text).toFixed(2)}</div>
    ),
  },
])
const dataSource = ref([])
const brandId = route.params.id
const brandDetail = ref({
  logoImageUrl: '',
})
let modalConfigs = ref({
  open: false,
  modalType: 'active',
})
const filter = ref({
  page: 1,
  size: 10,
  deal: 'Off',
  price: 'All',
  keywords: '',
  maxPrice: '',
  minPrice: '',
  currency: '$',
  commission: '',
  countryCode: 'US',
  availability: 'all',
  sort: '%2BbestSellers',
})
const pagination = ref({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
  hideOnSinglePage: true,
  onChange: (page) => {
    filter.value.page = page
    getProductList()
  },
})

onMounted(() => {
  publisherApi.getBrandDetail({ brandId }).then((res) => {
    if (res.success) {
      brandTitle.value = res.data.amazonBrandName
      breadcrumbsData.title = res.data.amazonBrandName
      breadcrumbsData.links.push({
        name: res.data.amazonBrandName,
        path: `/admin/brand/detail/${res.data.id}`,
      })
      brandDetail.value = res.data
      filter.value.countryCode = res.data.countryCode
      filter.value.currency = CURRENCY_MAPPER[res.data.countryCode].currency
    } 
    
    getProductList()
  })
})

// 获取商品列表
function getProductList() {
  const params = { ...filter.value, brandId }

  Object.keys(params).forEach((key) => {
    if (['price', 'Above', 'currency'].indexOf(key) != -1 || ['', null, undefined].indexOf(params[key]) != -1) {
      delete params[key]
    }
  })

  if (params['availability'] == 'all') {
    delete params['availability']
  }

  load.value = true

  publisherApi.getBrandProducts(params).then((res) => {
    load.value = false

    if (res.success) {
      productLoaded.value = true
      dataSource.value = res.data
      pagination.value.total = res.total
      pagination.value.current = params.page

      let hasPromoCode = false

      for (let product of res.data) {
        if (product.productDeals?.length) {
          columns.value[0]['width'] = 360
          columns.value[1]['width'] = 170

          for (let deal of product.productDeals) {
            if (deal.promoCode) {
              hasPromoCode = true
              columns.value[0]['width'] = 320
              columns.value[1]['width'] = 300
              break
            }
          }

          if (hasPromoCode) {
            break
          }
        }
      }
    }
  })
}
function setFilter(type, option) {
  if (type == 'page') {
    filter.value.page = option
  } else {
    if (type == 'price') {
      // price
      filter.value.price = option.label
      filter.value.minPrice = option.min || null
      filter.value.maxPrice = option.max || null
      filter.value.page = 1
    } else if (type == 'sort') {
      filter.value.sort = option
      filter.value.page = 1
    } else if (type == 'country') {
      filter.value.currency = option.currency
      filter.value.countryHost = option.value
      filter.value.countryCode = option.key.toUpperCase()
      setPriceCurrency()
    } else if (type == 'keywords') {
      if (option.type) {
        if (option.type == 'click') {
          // clear event
          filter.value.page = 1
        } else {
          return
        }
      } else {
        filter.value.keywords = option
        console.log('zjdgx: keywords: ' + option)
      }
    } else if (type == 'commission') {
      if (option) {
        const commission = ~~filter.value.commission + option

        filter.value.commission = commission || null
      } else {
        filter.value.commission = null
      }
    } else if (type == 'inStockOnly') {
      filter.value.inStockOnly = option
    } else if (type == 'brand') {
      if (option.title) {
        if (brands.value[option.index].selected) {
          filter.value.brandNames = filter.value.brandNames.filter(
            (title) => title != option.title
          )
          brands.value[option.index].selected = false
        } else {
          filter.value.brandNames.push(option.title)
          brands.value[option.index].selected = true
        }
      } else {
        const index = brands.value.findIndex((item) => item.title == option)

        brands.value[index].selected = false
        filter.value.brandNames = filter.value.brandNames.filter(
          (brand) => brand !== option
        )
      }
    } else if (type == 'popup') {
      filter.value = {...option}
    }

    filter.value.page = 1
  }

  getProductList()
}

// 分类点击事件
function gotoProductCategory(link) {
  localStorage.setItem('previewCategory', `${filter.value.countryCode}_${link.categoryId}_${link.categoryLevel}`)
}
</script>

<style lang="less" scoped>
@space: 16px;
@border-color: var (--border-4);
@border-radius: 8px;

@second-font: {
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
};

@third-font: var (--dark-3);

@flex: {
  display: flex;
  justify-content: space-between;
  align-items: center;
};

@card-header: {
  font-weight: 600;
  color: var(--dark-1);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
};

.brand-detail {
  padding: 16px 0;
  padding-top: unset;
}

.card-header {
  @flex();
  @card-header();
  height: 64px;
  line-height: 64px;
  padding: 0 @space;
  background: var(--color-white);
  border-radius: @border-radius @border-radius 0 0;
}

.brand-overview {
  border-radius: @border-radius;
  background: var(--color-white);
  box-shadow: var(--section-box-shadow-6);

  > h2 {
    height: 64px;
    padding: 0 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 64px;
    color: var(--dark-1);
    letter-spacing: 0.5px;
  }

  .brand-profile {
    display: flex;
    padding: @space;
    background-color: var(--color-white);
    border-radius: 0 0 @border-radius @border-radius;
    .brand-image {
      width: 136px;
      height: 136px;
      display: flex;
      overflow: hidden;
      margin-right: 12px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background: var(--primary-image);
      img {
        max-width: 100%;
        max-height: 100%;
      }

      span {
        display: flex;
        width: 136px;
        height: 136px;
        border-radius: 8px;
      }
    }

    .brand-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .brand-details {
        padding-left: 4px;

        .bio {
          display: flex;
          @card-header();
          margin-bottom: 4px;
          align-items: center;
        }

        .country {
          height: 20px;
          padding: 0 6px;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
          border-radius: 4px;
          align-items: center;
          color: var(--dark-2);
          display: inline-flex;
          background-color: var(--bg-gray-8);
          > .icon {
            font-size: 20px;
            margin-right: 4px;
          }
        }

        .content {
          font-size: 14px;
          line-height: 18px;
          color: var(--dark-2);
          letter-spacing: 0.3px;
        }
      }

      .brand-products {
        position: relative;
        padding: 0 5px;
        margin: 0 5px;

        ::before {
          position: absolute;
          content: '';
          display: block;
          top: 4px;
          left: -5px;
          width: 1px;
          height: 12px;
          background-color: var(--border-4);
        }

        ::after {
          position: absolute;
          content: '';
          display: block;
          top: 4px;
          right: -5px;
          width: 1px;
          height: 12px;
          background-color: var(--border-4);
        }

        div:first-of-type {
          color: var(--dark-3);
        }

        div:last-of-type {
          color: var(--dark-2);
          letter-spacing: 0.4px;
          line-height: 20px;
        }
      }

      .brand-items {
        div:first-of-type {
          color: var(--dark-3);
        }

        div:last-of-type {
          color: var(--dark-2);
          letter-spacing: 0.4px;
          line-height: 20px;
        }
      }

      .countryIcon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          margin-left: 3px;
          color: var(--dark-2);
          letter-spacing: 0.4px;
          line-height: 20px;
        }
      }

      .brand-terms {
        width: 244px;
        height: 28px;
        margin-top: 6px;
        padding: 0 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        line-height: 20px;
        text-decoration: underline;
        margin-right: 8px;
        white-space: nowrap;

        .icon {
          font-size: 18px;
          margin-left: 8px;
          color: var(--dark-2);
        }

        &:hover {
          background-color: var(--bg-gray-6);
          color: var(--dark-1);
        }

        &:active {
          background-color: var(--bg-gray-4);
          color: var(--dark-1);
        }
      }
    }
  }
}

.table-card {
  margin-top: @space;
  border-radius: @border-radius;
  box-shadow: var(--section-box-shadow-6);

  .table {
    padding: 0 @space;
    table-layout: auto;
    background-color: var(--color-white);
    border-radius: 0 0 @border-radius @border-radius;
    // :deep(.ant) {
    //   &-table-wrapper .ant-table {
    //     font-family: Sora, sans-self;
    //   }
    // }
  }

  .table-head {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 200px;
      margin-left: 16px;
      .search-icon {
        font-size: 20px;
        opacity: 0.5;
      }
    }

    .deals-filter {
      > span {
        margin-right: 8px;
        color: var(--dark-2);
      }
    }
    .flex-right {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    .filters {
      margin: 0 16px;
    }
  }

  .product-name {
    display: flex;
    align-items: center;

    .product-title {
      flex: 1;
      margin-left: 12px;

      > span {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-decoration: underline;
      }

      > div {
        font-weight: 500;
        font-size: 12px;
        color: var(--dark-3);
        letter-spacing: 0.3px;
        line-height: 16px;
      }

      .asin {
        margin-top: 2px;
      }
    }

    .product-img-show {
      width: 56px;
      height: 56px;
      border-radius: 8px;
      &.show-bg {
        background: var(--bg-gray-2);
      }

      img {
        border-radius: 8px;
        width: 56px;
        height: 56px;
        object-fit: contain;
      }
    }
  }

  .category {
    font-size: 14px;
    line-height: 22px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    text-decoration: underline;
  }
  .table-content:deep(.ant-table) {
    .product-price {
      @second-font();
      font-weight: 400;
      line-height: 19px;
    }

    .product-commission {
      @second-font();
      font-weight: 500;
      line-height: 19px;
    }
  }
  .no-deal {
    color: var(--dark-3);
  }
}
</style>
